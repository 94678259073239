
export const useSetElementHight = function (marginTop = 0, marginBottom = 0) {
  
  const { width, height } = useWindowSize()
  const NAV_BAR = 64
  let h = height.value - NAV_BAR -  marginTop - marginBottom 
  const HEIGHT_REMAINING = ref(h)
   

  return HEIGHT_REMAINING
}